import { useEffect } from 'react'

// Redirect
const YapzonePage = () => {
  useEffect(() => {
    window.location.replace('https://appriver3651001768.sharepoint.com/')
  })

  return null
}

export default YapzonePage
